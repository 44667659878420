<template>
    <div id="history">
        <div class="history-container">
            <div class="history-details">
                <div class="mobileshow" style="display:none">
                    <div class="history-details-title">{{ historyDetails.title }}</div>
                    <div class="history-operation-title">
                        <div class="owner">
                            Owned by
                            <div class="history-operation-address">{{ historyDetails.admin }}</div>
                        </div>
                        <a :href="shareTwitterHref" target="_blank" class="share-twitter" v-if="showShareOnTwitter">
                            <img src="@/assets/imgs/history/twitter.svg" alt="">
                        </a>
                    </div>
                </div>
                <div class="history-details-img" v-if="historyDetails.ipfsurl"><img :src="historyDetails.awsurl?historyDetails.awsurl:historyDetails.ipfsurl"></div>
                <div class="history-details-img" v-else><img :src="historyDetails.image"></div>
                <!--  <div :class="activeNames==1?'collapse-box':'collapse-box1'">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item name="1">
                            <img slot="title" src="../../assets/images/history-title.png" class="history-title">
                            <span slot="title">Properties</span>
                            <div class="collapse-content">
                                <div class="collapse-item">
                                    <div class="collapse-name">TYPE</div>
                                    <div class="collapse-info">image</div>
                                </div>
                                <div class="collapse-item">
                                    <div class="collapse-name">TYPE</div>
                                    <div class="collapse-info">image</div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div> -->
                <div :class="activeNames1==1?'collapse-box':'collapse-box1'">
                    <el-collapse v-model="activeNames1">
                        <el-collapse-item name="1">
                            <img slot="title" src="../../assets/images/history-title1.png" class="history-title">
                            <span slot="title">Details</span>
                            <div>
                                <div class="collapse-item-box">
                                    <div class="collapse-item-box-name">Contract Address</div>
                                    <div class="collapse-item-box-info">{{historyDetailsData}}</div>
                                </div>
                                <div class="collapse-item-box">
                                    <div class="collapse-item-box-name">Token ID</div>
                                    <div class="collapse-item-box-info">{{tokenidValue}}</div>
                                </div>
                                <div class="collapse-item-box">
                                    <div class="collapse-item-box-name">Blockchain</div>
                                    <div class="collapse-item-box-name" v-if="historyDetails.network == 'homestead'">Ethereum</div>
                                    <div class="collapse-item-box-name" v-if="historyDetails.network == 'rinkeby'">Rinkeby</div>
                                    <div class="collapse-item-box-name" v-if="historyDetails.network == 'BscMainnet'">BNB Chain</div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <div class="history-operation">
                <div class="history-details-title">{{ historyDetails.title }}</div>
                <div class="history-operation-title">
                    <div class="owner">
                        Owned by
                        <div class="history-operation-address">{{ historyDetails.admin }}</div>
                    </div>
                    <a :href="shareTwitterHref" target="_blank" class="share-twitter" v-if="showShareOnTwitter">
                        <img src="@/assets/imgs/history/twitter.svg" alt=""> <span>Share on Twitter</span>
                    </a>
                </div>
                <div class="history-operation-content">
                    <div class="description-box">
                        <img src="../../assets/images/subject.png" class="subject">
                        <span class="description">Description</span>
                    </div>
                    <div class="description-box1">
                        <div class="history-operation-right">
                            <div class="history-operation-contact">Contact
                                <div class="history-operation-contactInfo">
                                    <a class="history-operation-contactInfo-route" target="_blank" :href="`https://ipfs.io/ipfs/${historyDetails.texthash}`">{{ texthashValue }}</a></div>
                            </div>
                            <div class="history-details-text" v-html="historyDetails.description"></div>
                        </div>
                    </div>
                </div>
                <div class="history-operation-content1">
                    <div :class="isFollow == 'false'?'description-box2':'description-box'">
                        <img src="../../assets/images/schedule.png" class="subject">
                        <span class="description1">FOLLOW {{ historyDetails.timeago }}</span>
                    </div>
                    <div class="history-operation-left-content">
                        <div class="history-operation-title1">Current price</div>
                        <div class="history-operation-price">
                            <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                            <div class="history-operation-price1"><span v-if="historyDetails.price=='null' || historyDetails.price===''">0</span><span v-else>{{ historyDetails.price }}</span></div>
                        </div>
                        <!-- come from - market -->
                        <div class="history-operation-buy" v-if="actionsState=='true'">
                            <div v-show="isShow == true" class="history-operation-follow buttonHover2" :class="{'followBanElection': isFollow=='false'}" @click="follow">
                                <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                                <span class="buyNow-box" v-else>
                                    <img src="../../assets/images/followIcon.png" class="buyNowIcon">
                                    <div>FOLLOW</div>
                                </span>
                            </div>
                            <div class="history-operation-button buttonHover1" :class="{'buyBanElection': isBuy=='false'||historyDetails.state==null||historyDetails.state==''}" v-if="isTokenAddress == 'tokenOne'" @click="bugNow">
                                <span v-if="operationLoading_other" class="loading_ani">Loading<span class="dotting"></span></span>
                                <span class="buyNow-box" v-else>
                                    <img src="../../assets/images/buyNowIcon.png" class="buyNowIcon">
                                    <div>BUY NOW</div>
                                </span>
                            </div>
                            <div class="history-operation-button buttonHover1" :class="{'buyBanElection': isBuy=='false'||historyDetails.state==null||historyDetails.state==''}" v-if="isTokenAddress=='tokenThree'" @click="bugNow1">
                                <span v-if="operationLoading_other" class="loading_ani">Loading<span class="dotting"></span></span>
                                <span class="buyNow-box" v-else>
                                    <img src="../../assets/images/buyNowIcon.png" class="buyNowIcon">
                                    <div>BUY NOW</div>
                                </span>
                            </div>
                        </div>
                        <div class="history-operation-buy" v-else-if="historyDetails.state === 'sale' && actionsState=='false'">
                            <div class="history-button buttonHover2" @click="isTokenAddress=='tokenThree'?cancelSale1():cancelSale()">
                                <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                                <span v-else>Cancel Listing</span>
                            </div>
                        </div>
                        <div class="history-operation-buy" v-else>
                            <div class="history-operation-follow buttonHover2 history-operation-sell" @click="goSellTransfer(historyDetails,'sell')">
                                <span>Sell</span>
                            </div>
                            <div class="history-operation-button buttonHover1" @click="goSellTransfer(historyDetails,'Transfer')">
                                <span>Transfer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobileshow" style="display:none;">
                <el-collapse v-model="activeNames2">
                    <el-collapse-item name="1">
                        <img slot="title" src="../../assets/imgs/history/1.svg" class="history-title">
                        <span slot="title">Current price</span>
                        <div class="price-content">
                            <div class="history-operation-price">
                                <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                                <div class="history-operation-price1"><span v-if="historyDetails.price=='null' || historyDetails.price===''">0</span><span v-else>{{ historyDetails.price }}</span></div>
                            </div>
                            <!-- come from - market -->
                            <div class="history-operation-buy" v-if="actionsState=='true'">
                                <div v-show="isShow == true" class="history-operation-follow buttonHover2" :class="{'followBanElection': isFollow=='false'}" @click="follow">
                                    <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                                    <span class="buyNow-box" v-else>
                                        <img src="../../assets/images/followIcon.png" class="buyNowIcon">
                                        <div>FOLLOW</div>
                                    </span>
                                </div>
                                <div class="history-operation-button buttonHover1" :class="{'buyBanElection': isBuy=='false'||historyDetails.state==null||historyDetails.state==''}" v-if="isTokenAddress == 'tokenOne'" @click="bugNow">
                                    <span v-if="operationLoading_other" class="loading_ani">Loading<span class="dotting"></span></span>
                                    <span class="buyNow-box" v-else>
                                        <img src="../../assets/images/buyNowIcon.png" class="buyNowIcon">
                                        <div>BUY NOW</div>
                                    </span>
                                </div>
                                <div class="history-operation-button buttonHover1" :class="{'buyBanElection': isBuy=='false'||historyDetails.state==null||historyDetails.state==''}" v-if="isTokenAddress=='tokenThree'" @click="bugNow1">
                                    <span v-if="operationLoading_other" class="loading_ani">Loading<span class="dotting"></span></span>
                                    <span class="buyNow-box" v-else>
                                        <img src="../../assets/images/buyNowIcon.png" class="buyNowIcon">
                                        <div>BUY NOW</div>
                                    </span>
                                </div>
                            </div>
                            <div class="history-operation-buy" v-else-if="historyDetails.state === 'sale' && actionsState=='false'">
                                <div class="history-button buttonHover2" @click="isTokenAddress=='tokenThree'?cancelSale1():cancelSale()">
                                    <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                                    <span v-else>Cancel Listing</span>
                                </div>
                            </div>
                            <div class="history-operation-buy" v-else>
                                <div class="history-operation-follow buttonHover2 history-operation-sell" @click="goSellTransfer(historyDetails,'sell')">
                                    <span>Sell</span>
                                </div>
                                <div class="history-operation-button buttonHover1" @click="goSellTransfer(historyDetails,'Transfer')">
                                    <span>Transfer</span>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <img slot="title" src="../../assets/imgs/history/2.svg" class="history-title">
                        <span slot="title">Description</span>
                        <div class="description-box1">
                            <div class="history-operation-right">
                                <div class="history-operation-contact">Contact
                                    <div class="history-operation-contactInfo">
                                        <a class="history-operation-contactInfo-route" target="_blank" :href="`https://ipfs.io/ipfs/${historyDetails.texthash}`">{{ texthashValue }}</a></div>
                                </div>
                                <div class="history-details-text" v-html="historyDetails.description"></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="3">
                        <img slot="title" src="../../assets/imgs/history/3.svg" class="history-title">
                        <span slot="title">Details</span>
                        <div class="details-box">
                            <div class="collapse-item-box">
                                <div class="collapse-item-box-name">Contract Address</div>
                                <div class="collapse-item-box-info">{{historyDetailsData}}</div>
                            </div>
                            <div class="collapse-item-box">
                                <div class="collapse-item-box-name">Token ID</div>
                                <div class="collapse-item-box-info">{{tokenidValue}}</div>
                            </div>
                            <div class="collapse-item-box">
                                <div class="collapse-item-box-name">Blockchain</div>
                                <div class="collapse-item-box-name" v-if="historyDetails.network == 'homestead'">Ethereum</div>
                                <div class="collapse-item-box-name" v-if="historyDetails.network == 'rinkeby'">Rinkeby</div>
                                <div class="collapse-item-box-name" v-if="historyDetails.network == 'BscMainnet'">BNB Chain</div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>
<script>
import { queryHistoryMarket } from "../../web3/HistoryMarket";
const address = require('../../web3/web3Config.js');
const wallet = require('../../web3/common/wallet.js');
import { createHistoryV1ERC721 } from "../../web3/HistoryV1ERC721";
import { chainName, chainImg, networkName } from "../../web3/web3Config";
import { getChainIdNetwork } from "../../web3/common/wallet";

export default {
    name: "History",
    inject: ['reload'],
    data() {
        return {
            chainImg: chainImg,
            base_url1: 'https://api.blockhistory.io',
            base_url2: 'https://api.historydao.io',
            activeNames: ['1'],
            activeNames1: ['1'],
            activeNames2: ['1'],
            baseUrlData: '',
            historyDetails: {},
            historyDetailsData: '',
            isShow: '',
            isBuy: 'false',
            isFollow: 'false',
            actionsState: true,
            tokenaddress: '',
            operationLoading: false,
            operationLoading_other: false,
            isTokenAddress: '',
            chainName: chainName,
            HistoryERC721: '',
            HistorySeriesERC721: '',
            networkIsShow: '',
            tokenidValue: '',
            texthashValue: '',
            itemData: {},
            historyDetails_admin: '',
        }
    },

    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },
        chainId() {
            return this.$store.getters.walletInfo.chainId;
        },
        showShareOnTwitter() {
            let flag = false;
            try {
                flag = this.historyDetails_admin === this.mapState.address.toLocaleLowerCase();
            } catch (e) {
                console.log(e);
            }
            return flag;
        },
        shareTwitterHref() {
            return `https://twitter.com/intent/tweet?text=${this.historyDetails.title}&url=${this.base_url2}/history/sharetwitter?tokenid=${this.historyDetails.tokenid}`;

        },

    },
    watch: {
        '$store.getters.initStatusComplete': {
            immediate: true,
            handler(flag) {
                if (flag) {
                    this.init();
                }
            }
        }
    },
    mounted() {},
    methods: {
        async init() {
            this.baseUrlData = localStorage.getItem('baseUrl')

            let res = '';
            if (this.mapState.chainId) {
                res = this.mapState.chainId
            } else {
                res = await getChainIdNetwork();
            }

            this.HistoryERC721 = address.address[this.chainName[res]].HistoryERC721;
            this.HistorySeriesERC721 = address.address[this.chainName[res]].HistorySeriesERC721;
            if (this.$route.query.tokenIdData) {
                this.queryHistoryDetails1(this.$route.query.tokenIdData, this.$route.query.tokenaddress)
                //is follow and is bug
                this.isNewFollowBuy(this.$route.query.tokenIdData, this.$route.query.tokenaddress);
            }
            if (this.$route.query.tokenId) {
                this.queryHistoryDetails(this.$route.query.tokenId, this.$route.query.tokenaddress);
                //is follow and is bug
                this.isFollowBuy(this.$route.query.tokenId);
            }
            this.actionsState = this.$route.query.actionsState;
            if (this.HistoryERC721 == this.$route.query.tokenaddress) {
                this.isTokenAddress = 'tokenOne'
            }
            if ((this.HistoryERC721 || this.HistorySeriesERC721) !== this.$route.query.tokenaddress) {
                this.isTokenAddress = 'tokenThree'
            }
            this.networkIsShow = networkName[res]

        },
        isFollowBuy(tokenid) {
            let _this = this;
            let ctr = queryHistoryMarket();
            let erc721 = createHistoryV1ERC721();

            if (this.mapState.chainId) {
                //is buy
                ctr.tokenSaleInfo(this.HistoryERC721, tokenid).then(buyState => {
                    if (buyState.onSale === false) {
                        _this.isBuy = 'false';
                    } else {
                        _this.isBuy = 'true';
                    }
                    this.$forceUpdate();
                    //is follow
                    erc721.tokenEventId(tokenid).then(eventId => {
                        //follow time
                        erc721.getConfig().then(mintStartTime => {
                            erc721.eventData(eventId).then(firstMintTime => {
                                let startTime = Number(mintStartTime._mintStartTime) + Number(firstMintTime.firstMintTime)
                                let endTime = Number(mintStartTime._mintEndTime) + Number(firstMintTime.firstMintTime)
                                if (Date.parse(new Date()).toString().substr(0, 10) >= startTime && Date.parse(new Date()).toString().substr(0, 10) <= endTime) {
                                    _this.isFollow = 'true';
                                } else {
                                    _this.isFollow = 'false';
                                }
                                this.$forceUpdate();
                            })
                        })
                    })
                })
            }
        },
        isNewFollowBuy(tokenid, tokenaddress) {
            let _this = this;
            let ctr = queryHistoryMarket();
            let erc721 = createHistoryV1ERC721();

            if (this.mapState.chainId) {
                //is buy
                ctr.tokenSaleInfo(this.HistorySeriesERC721 == tokenaddress ? this.HistorySeriesERC721 : tokenaddress, tokenid).then(buyState => {
                    if (buyState.onSale === false) {
                        _this.isBuy = 'false';
                    } else {
                        _this.isBuy = 'true';
                    }
                    this.$forceUpdate();
                    //is follow
                    erc721.tokenEventId(tokenid).then(eventId => {
                        //follow time
                        erc721.getConfig().then(mintStartTime => {
                            erc721.eventData(eventId).then(firstMintTime => {
                                let startTime = Number(mintStartTime._mintStartTime) + Number(firstMintTime.firstMintTime)
                                let endTime = Number(mintStartTime._mintEndTime) + Number(firstMintTime.firstMintTime)
                                if (Date.parse(new Date()).toString().substr(0, 10) >= startTime && Date.parse(new Date()).toString().substr(0, 10) <= endTime) {
                                    _this.isFollow = 'false';
                                } else {
                                    _this.isFollow = 'false';
                                }
                                this.$forceUpdate();
                            })
                        })
                    })
                })
            }






        },
        queryHistoryDetails(tokenId, tokenaddress) {
            let _this = this;
            let params = {
                tokenaddress: tokenaddress,
                tokenid: tokenId
            };
            this.$axios.get(this.baseUrlData + this.$api.post_genericnftinfo, params).then(res => {
                _this.historyDetails = res.result;
                let address = this.historyDetails.admin;
                this.historyDetails_admin = address.toLocaleLowerCase();
                let startText = address.substring(0, 5);
                let endText = address.substring(address.length - 4, address.length);
                this.historyDetails.admin = startText + '...' + endText;

                let tokenaddress = this.historyDetails.tokenaddress;
                let startText1 = tokenaddress.substring(0, 5);
                let endText1 = tokenaddress.substring(tokenaddress.length - 4, tokenaddress.length);
                this.historyDetailsData = startText1 + '...' + endText1;

                let tokenid = this.historyDetails.tokenid;
                let startText2 = tokenid.substring(0, 5);
                let endText2 = tokenid.substring(tokenid.length - 4, tokenid.length);
                this.tokenidValue = startText2 + '...' + endText2;

                let texthash = res.result.texthash
                let startText3 = texthash.substring(0, 5);
                let endText3 = texthash.substring(texthash.length - 4, texthash.length);
                if (res.result.texthash !== "") {
                    this.texthashValue = startText3 + '...' + endText3;
                }

                if (tokenaddress == _this.HistoryERC721) {
                    _this.isShow = true
                }
            })
        },
        queryHistoryDetails1(tokenId, tokenaddress) {
            let _this = this;
            let params = {
                tokenaddress: tokenaddress,
                tokenid: tokenId
            };
            this.$axios.get(this.baseUrlData + this.$api.post_genericnftinfo, params).then(res => {
                _this.historyDetails = res.result;
                let address = this.historyDetails.admin;
                this.historyDetails_admin = address.toLocaleLowerCase();

                let startText = address.substring(0, 5);
                let endText = address.substring(address.length - 4, address.length);
                this.historyDetails.admin = startText + '...' + endText;

                let tokenaddress = this.historyDetails.tokenaddress;
                let startText1 = tokenaddress.substring(0, 5);
                let endText1 = tokenaddress.substring(tokenaddress.length - 4, tokenaddress.length);
                this.historyDetailsData = startText1 + '...' + endText1;

                let tokenid = this.historyDetails.tokenid;
                let startText2 = tokenid.substring(0, 5);
                let endText2 = tokenid.substring(tokenid.length - 4, tokenid.length);
                this.tokenidValue = startText2 + '...' + endText2;

                let texthash = res.result.texthash
                let startText3 = texthash.substring(0, 5);
                let endText3 = texthash.substring(texthash.length - 4, texthash.length);
                if (res.result.texthash !== "") {
                    this.texthashValue = startText3 + '...' + endText3;
                }

                if (tokenaddress == _this.HistorySeriesERC721) {
                    _this.isShow = false
                }
            })
        },
        bugNow() {
            if (this.isBuy != 'false' && this.historyDetails.state != null && this.historyDetails.state != '') {
                this.operationLoading_other = true;
                let ctr = queryHistoryMarket();
                if (this.mapState.chainId) {
                    wallet.getWalletInfo().then((info) => {
                        ctr.tokenSaleInfo(this.HistoryERC721, this.historyDetails.tokenid).then(buyState => {
                            if (buyState.onSale === false) {
                                this.operationLoading_other = false;
                                this.$message({
                                    message: 'Not available for purchase',
                                    type: 'warning'
                                });
                            } else {
                                ctr.buyNft(this.HistoryERC721, this.historyDetails.tokenid, info.address, buyState.price).then(() => {
                                    let params = {
                                        admin: info.address.toLowerCase(),
                                        tokenowner: this.historyDetails.admin.toLowerCase(),
                                        tokenid: this.$route.query.tokenId,
                                    };
                                    this.$axios.post(this.baseUrlData + this.$api.post_buytoken, params).then(() => {
                                        this.operationLoading_other = false;
                                        this.$message({
                                            message: 'Operation succeeded',
                                            type: 'success'
                                        });
                                        console.log("success: bugNow")
                                    })
                                }).catch(() => {
                                    this.operationLoading_other = false;
                                })
                            }
                        })
                    })
                }
            }
        },
        bugNow1() {
            let _this = this;
            if (this.isBuy != 'false' && this.historyDetails.state != null && this.historyDetails.state != '') {
                this.operationLoading_other = true;
                let ctr = queryHistoryMarket();

                if (this.mapState.chainId) {

                    wallet.getWalletInfo().then((info) => {
                        ctr.tokenSaleInfo(this.HistorySeriesERC721 == this.$route.query.tokenaddress ? this.HistorySeriesERC721 : this.$route.query.tokenaddress, this.$route.query.tokenIdData).then(buyState => {
                            if (buyState.onSale === false) {
                                this.operationLoading_other = false;
                                this.$message({
                                    message: 'Not available for purchase',
                                    type: 'warning'
                                });
                            } else {
                                ctr.buyNft(this.HistorySeriesERC721 == this.$route.query.tokenaddress ? this.HistorySeriesERC721 : this.$route.query.tokenaddress, this.$route.query.tokenIdData, info.address, buyState.price).then(() => {
                                    let params = {
                                        admin: info.address.toLowerCase(),
                                        tokenowner: this.historyDetails.admin.toLowerCase(),
                                        tokenid: this.$route.query.tokenIdData,
                                        network: info.network,
                                        tokenaddress: this.$route.query.tokenaddress
                                    };
                                    this.$axios.post(_this.baseUrlData + _this.$api.post_buyseriestoken, params).then((res) => {
                                        this.operationLoading_other = false;
                                        this.$message({
                                            message: 'Operation succeeded',
                                            type: 'success'
                                        });
                                        console.log("success: bugNow")
                                        _this.$router.push({ path: '/market' })
                                    })
                                }).catch(() => {
                                    this.operationLoading_other = false;
                                })
                            }
                        })
                    })

                }





            }
        },
        follow() {
            if (this.isFollow == 'true') {
                this.operationLoading = true;
                let ctr = createHistoryV1ERC721();

                if (this.mapState.chainId) {
                    wallet.getWalletInfo().then((info) => {
                        ctr.tokenEventId(this.historyDetails.tokenid).then(eventId => {
                            //follow time
                            ctr.getConfig().then(mintStartTime => {
                                ctr.eventData(eventId).then(firstMintTime => {
                                    let startTime = Number(mintStartTime._mintStartTime) + Number(firstMintTime.firstMintTime)
                                    let endTime = Number(mintStartTime._mintEndTime) + Number(firstMintTime.firstMintTime)
                                    if (Date.parse(new Date()).toString().substr(0, 10) >= startTime && Date.parse(new Date()).toString().substr(0, 10) <= endTime) {
                                        ctr.followHistoryEvent(eventId).then((tokenid) => {
                                            let params = {
                                                admin: info.address.toLowerCase(),
                                                eventid: Number(eventId),
                                                tokenid: String(tokenid)
                                            };
                                            this.$axios.post(this.baseUrlData + this.$api.post_followtoken, params).then(() => {
                                                this.operationLoading = false;
                                                this.$message({
                                                    message: 'Operation succeeded',
                                                    type: 'success'
                                                });
                                                console.log("success: follow")
                                            })
                                        }).catch(() => {
                                            this.operationLoading = false;
                                        })
                                    } else {
                                        this.operationLoading = false;
                                        this.$message({
                                            message: 'Allowed time exceeded',
                                            type: 'warning'
                                        });
                                    }
                                })
                            })
                        })
                    })
                }
            }
        },
        cancelSale() {
            this.operationLoading = true;
            let _this = this;
            let erc21 = queryHistoryMarket();
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    erc21.sellNft(this.HistoryERC721, this.$route.query.tokenId, 0).then(() => {
                        let params = {
                            admin: info.address.toLowerCase(),
                            tokenid: this.$route.query.tokenId
                        };
                        this.$axios.post(this.baseUrlData + this.$api.post_cancelSale, params).then(() => {
                            this.operationLoading = false;
                            this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            setTimeout(() => {
                                this.$router.push({ path: '/myNFTs' })
                                this.reload()
                            }, 1500)
                        })
                    }).catch(() => {
                        this.operationLoading = false;
                    })
                })
            }
        },
        cancelSale1() {
            this.operationLoading = true;
            let _this = this
            let erc21 = queryHistoryMarket();
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    erc21.sellNft(this.$route.query.tokenaddress == _this.HistorySeriesERC721 ? _this.HistorySeriesERC721 : this.$route.query.tokenaddress, _this.$route.query.tokenIdData, 0).then(() => {
                        let params = {
                            admin: info.address.toLowerCase(),
                            tokenid: _this.$route.query.tokenIdData,
                            network: info.network,
                            tokenaddress: this.$route.query.tokenaddress
                        };
                        _this.$axios.post(_this.baseUrlData + _this.$api.post_cancelseriessale, params).then((res) => {
                            _this.operationLoading = false;
                            _this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            setTimeout(() => {
                                _this.$router.push({ path: '/myNFTs' })
                                _this.reload()
                            }, 1500)
                        })
                    }).catch(() => {
                        _this.operationLoading = false;
                    })
                })
            }
        },
        goSellTransfer(item, view) {
            this.itemData = {
                tokenid: item.tokenid,
                price: 1,
                currentPage: 3,
            }
            let transactionState = view
            let historyItem = item
            let tokenaddress = item.tokenaddress
            this.$router.push({ path: '/transaction', query: { itemData: JSON.stringify(this.itemData), item: JSON.stringify(historyItem), transactionState: transactionState, tokenaddress: tokenaddress } })
        }
    }
}
</script>
<style lang="scss" src="../../assets/css/historyDetails.scss" scoped></style>
<style lang="scss" scoped>
.history-operation-title {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .owner {
        display: flex;
        align-items: center;
    }

    .share-twitter {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        border: 1px solid #4B9AE9;
        border-radius: 10px;
        padding: 0 14px;
        // width: 177px;
        height: 32px;
        margin-left: 20px;

        img {
            width: 20px;
            margin-right: 10px;
        }

        span {
            font-family: Poppins-Regular, Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            /* identical to box height, or 150% */
            color: #353840;
        }

    }

}
</style>
<style lang="scss" scoped>
@media (max-width: 1125px) {
    #history {
        margin: 3.68641rem 0 0;
        padding: 1.3312rem .768rem;

        .history-container {
            padding: 0;
            flex-direction: column;

            .history-details {
                .mobileshow {
                    display: block !important;

                    .history-details-title {
                        /*font-family: 'Poppins';*/
                        font-style: normal;
                        font-weight: 600;
                        font-size: .9216rem;
                        line-height: 1.3824rem;
                        /* identical to box height */
                        color: #353840;
                        margin-bottom: .4096rem;
                    }

                    .history-operation-title {
                        margin-bottom: 1.024rem;
                        /*font-family: 'Poppins';*/
                        font-style: normal;
                        font-weight: 400;
                        font-size: .7168rem;
                        line-height: 1.1264rem;
                        /* identical to box height, or 161% */
                        color: #707A83;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0;

                        .owner {
                            .history-operation-address {
                                /*font-family: 'Poppins';*/
                                font-style: normal;
                                font-weight: 400;
                                font-size: .7168rem;
                                line-height: 1.1264rem;
                                /* identical to box height, or 161% */
                                color: #FFC107;
                            }
                        }

                        .share-twitter {
                            width: 1.024rem;
                            height: 1.024rem;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 100%;
                                height: auto;
                                margin: 0;
                            }

                            span {}
                        }
                    }
                }


                .history-details-img {
                    width: 17.66405rem;
                    margin-bottom: .8192rem;

                    img {
                        width: 100%;
                        height: auto;
                        border-radius: .512rem;
                    }
                }

                .collapse-box,
                .collapse-box1 {
                    display: none !important;

                    .el-collapse {
                        .el-collapse-item {
                            &.is-active {}

                            .el-collapse-item__header {
                                &.is-active {}
                            }

                            .el-collapse-item__wrap {
                                .el-collapse-item__content {
                                    .collapse-item-box {
                                        .collapse-item-box-name {}

                                        .collapse-item-box-info {}
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .history-operation {
                margin: 0;
                display: none !important;

                .history-details-title,
                .history-operation-title {
                    display: none !important;
                }

                .history-operation-title {

                    .owner {
                        .history-operation-address {}
                    }

                    .share-twitter {
                        img {}

                        span {}
                    }
                }

                .history-operation-content {
                    .description-box {
                        img {}

                        .description {}
                    }

                    .description-box1 {
                        .history-operation-right {
                            .history-operation-contact {
                                .history-operation-contactInfo {
                                    .history-operation-contactInfo-route {}
                                }
                            }

                            .history-details-text {}
                        }

                    }

                }

                .history-operation-content1 {
                    .description-box2 {
                        img {}

                        .description1 {}
                    }

                    .history-operation-left-content {
                        .history-operation-title1 {}

                        .history-operation-price {
                            .history-price-logo {
                                img {}
                            }

                            .history-operation-price1 {}
                        }

                        .history-operation-buy {
                            .history-operation-follow {}

                            .history-operation-button {}
                        }
                    }
                }
            }

            ::v-deep .mobileshow {
                display: block !important;

                .el-collapse {
                    border: 0;

                    .el-collapse-item {
                        width: 17.66405rem;
                        // min-height: 2.66241rem;
                        background: #FFFFFF;
                        border: 1px solid #E5E8EB;
                        border-radius: .512rem;
                        margin-bottom: .6144rem;
                        overflow: hidden;

                        &.is-active {
                            .el-collapse-item__header {
                                .el-collapse-item__arrow {
                                    transform: rotate(0deg);

                                }

                            }
                        }

                        .el-collapse-item__header {
                            padding: 0 .7168rem;
                            border: 0;

                            height: 2.66241rem;
                            line-height: 2.66241rem;

                            .history-title {
                                width: 1.2288rem;
                                height: 1.2288rem;
                                margin-right: .4096rem;
                            }

                            span {
                                /*font-family: 'Poppins';*/
                                font-style: normal;
                                font-weight: 500;
                                font-size: .8192rem;
                                line-height: 1.2288rem;
                                /* identical to box height, or 150% */
                                color: #353840;

                            }

                            .el-collapse-item__arrow {
                                margin: 0 0 0 auto;
                                // font-weight: 900;
                                width: 1.2288rem;
                                height: 1.2288rem;
                                background: url(../../assets/imgs/history/4.svg) no-repeat center center;
                                background-size: .6144rem .6144rem;
                                transform: rotate(180deg);

                                &:before {
                                    display: none;
                                }
                            }



                            &.is-active {}
                        }

                        .el-collapse-item__wrap {
                            border: 0;

                            .el-collapse-item__content {
                                border-top: 1px solid #E5E8EB;
                                padding: 0;



                                .price-content {
                                    background: #FFFEF9;
                                    padding: .7168rem .6144rem;


                                    .history-operation-price {
                                        margin: 0 0 .4096rem 0;

                                        .history-price-logo {
                                            width: 1.2288rem;
                                            height: 1.2288rem;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            img {
                                                width: 100%;
                                                height: auto;
                                            }
                                        }

                                        .history-operation-price1 {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 1.536rem;
                                            line-height: 2.30401rem;
                                            /* identical to box height, or 150% */


                                            color: #353840;

                                        }
                                    }

                                    .history-operation-buy {
                                        margin: 0;
                                        align-items: center;
                                        justify-content: space-between;

                                        .history-operation-follow,
                                        .history-operation-button {
                                            width: 8.03842rem;
                                            height: 2.56001rem;
                                            margin: 0;
                                            border-radius: .4096rem;
                                            font-family: 'Poppins';
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: .7168rem;
                                            line-height: 2.56001rem;
                                            /* identical to box height, or 171% */

                                            text-align: center;
                                            background: #FFE161;
                                        }

                                        .history-operation-button {
                                            background: #fff;
                                            border: 1px solid #FFE161;


                                        }
                                    }
                                }




                                .description-box1 {
                                    background: #FFFEF9;
                                    padding: .7168rem .6144rem;


                                    .history-operation-right {
                                        margin: 0;

                                        .history-operation-contact {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: .768rem;
                                            line-height: 1.1264rem;
                                            /* identical to box height, or 150% */
                                            color: #707A83;
                                            margin-right: .4096rem;

                                            .history-operation-contactInfo {
                                                .history-operation-contactInfo-route {
                                                    /*font-family: 'Poppins';*/
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    font-size: .768rem;
                                                    line-height: 1.1264rem;
                                                    /* identical to box height, or 150% */
                                                    color: #FFC107;

                                                }
                                            }
                                        }

                                        .history-details-text {
                                            margin: .4096rem 0 0;
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: .7168rem;
                                            line-height: 1.3312rem;
                                            /* or 186% */
                                            color: #707A83;
                                            width: 100%;
                                            max-height: none;

                                        }
                                    }

                                }

                                .details-box {
                                    background: #FFFEF9;
                                    padding: .7168rem .6144rem;


                                    .collapse-item-box {
                                        &:not(:last-child) {
                                            margin-bottom: .4096rem;
                                        }

                                        .collapse-item-box-name {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: .7168rem;
                                            line-height: 1.1264rem;
                                            /* identical to box height, or 161% */
                                            color: #707A83;
                                        }

                                        .collapse-item-box-info {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: .7168rem;
                                            line-height: 1.0752rem;
                                            /* identical to box height, or 150% */
                                            text-align: right;
                                            color: #FFC107;
                                        }
                                    }
                                }




                            }
                        }
                    }
                }


            }
        }
    }




    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
}
</style>